import TrustpilotComponent from '@pretto/bricks/website/utility/Trustpilot'

import * as S from '../styles'

const Trustpilot = props => (
  <S.BlockLarge>
    <TrustpilotComponent {...props} />
  </S.BlockLarge>
)

export default Trustpilot
