export const PARAMS = {
  className: 'trustpilot-widget',
  'data-businessunit-id': '5943f5510000ff0005a4b5cb',
  'data-locale': 'fr-FR',
  'data-style-width': '100%',
  'data-theme': 'light',
  id: 'trustbox',
}

export const TEMPLATES = {
  carousel: {
    'data-style-height': '140px',
    'data-template-id': '53aa8912dec7e10d38f59f36',
  },
  'drop-down': {
    'data-style-height': '30px',
    'data-template-id': '5418052cfbfb950d88702476',
  },
  grid: {
    'data-style-height': '500px',
    'data-template-id': '539adbd6dec7e10e686debee',
  },
  home: {
    'data-style-height': '240px',
    'data-template-id': '54ad5defc6454f065c28af8b',
    'data-theme': 'dark',
  },
  horizontal: {
    'data-style-height': '28px',
    'data-template-id': '5406e65db0d04a09e042d5fc',
  },
  'micro-button': {
    'data-style-height': '25px',
    'data-template-id': '5419b757fa0340045cd0c938',
  },
  'micro-combo': {
    'data-style-height': '20px',
    'data-template-id': '5419b6ffb0d04a076446a9af',
  },
  'micro-star': {
    'data-style-height': '24px',
    'data-template-id': '5419b732fbfb950b10de65e5',
  },
  'micro-trustscore': {
    'data-style-height': '20px',
    'data-template-id': '5419b637fa0340045cd0c936',
  },
  mini: {
    'data-style-height': '150px',
    'data-template-id': '53aa8807dec7e10d38f59f32',
  },
  'mini-carousel': {
    'data-style-height': '350px',
    'data-template-id': '539ad0ffdec7e10e686debd7',
  },
  'product-mini': {
    'data-style-height': '24px',
    'data-template-id': '54d39695764ea907c0f34825',
  },
  'review-collector': {
    'data-style-height': '52px',
    'data-template-id': '56278e9abfbbba0bdcd568bc',
  },
  slider: {
    'data-style-height': '240px',
    'data-template-id': '54ad5defc6454f065c28af8b',
  },
}
