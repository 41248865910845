import TrustpilotContext from '@pretto/bricks/website/utility/TrustpilotContext'

import { Script } from 'gatsby'
import PropTypes from 'prop-types'
import { Component } from 'react'
import Helmet from 'react-helmet'

import { PARAMS, TEMPLATES } from './config'
import * as schemas from './schemas'

export default class Trustpilot extends Component {
  static propTypes = {
    className: PropTypes.string,
    ignoreRichSnippet: PropTypes.bool,
    stars: PropTypes.string,
    template: PropTypes.oneOf(Object.keys(TEMPLATES)),
    type: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    ignoreRichSnippet: false,
    stars: '4,5',
    template: 'carousel',
    type: 'service,webApplication',
  }

  componentDidMount() {
    const trustbox = document.getElementById('trustbox')

    if (trustbox) {
      window.Trustpilot && window.Trustpilot.loadFromElement(trustbox)
    }
  }

  render() {
    const { className, ignoreRichSnippet, type, template, stars } = this.props

    const props = {
      ...PARAMS,
      ...TEMPLATES[template],
      'data-stars': stars,
    }

    return (
      <TrustpilotContext.Consumer>
        {schemaData => {
          const schema = {
            '@context': 'http://schema.org',
            '@graph': type.split(',').map(type => schemas[type](schemaData)),
          }

          return (
            <div
              className={[...className.split(/[\s]+/).filter(Boolean), `trustpilot trustpilot--${template}`].join(' ')}
            >
              <Script
                id="trustpilot"
                key="trustpilot"
                src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                strategy="idle"
              />
              <Helmet
                script={[
                  ...(ignoreRichSnippet
                    ? []
                    : [
                        {
                          innerHTML: JSON.stringify(schema),
                          type: 'application/ld+json',
                        },
                      ]),
                ]}
              ></Helmet>

              <div {...props}>
                <a href="https://fr.trustpilot.com/review/pretto.fr" target="blank">
                  Trustpilot
                </a>
              </div>
            </div>
          )
        }}
      </TrustpilotContext.Consumer>
    )
  }
}
