import url from 'url'

export const organization = ({ organizationSchema }) => ({
  '@type': 'Organization',
  ...organizationSchema,
})
export const service = ({ baseSiteUrl, organizationSchema }) => {
  return {
    '@type': 'Service',
    additionalType: url.resolve(baseSiteUrl, '/en-savoir-plus/'),
    brand: organizationSchema,
    broker: organizationSchema,
    provider: organizationSchema,
    serviceType: 'Courtier en prêt immobilier',
    slogan: 'Le crédit immobilier, simple',
    termsOfService: url.resolve(baseSiteUrl, '/cgu/'),
  }
}
export const webApplication = ({ name, organizationSchema, ratingSchema, sameAs, url }) => ({
  '@type': 'WebApplication',
  aggregateRating: ratingSchema,
  applicationCategory: 'Fintech',
  applicationSubCategory: 'Mortgage',
  name,
  offers: {
    businessFunction: 'http://purl.org/goodrelations/v1#ProvideService',
    description: 'Pretto trouve le meilleur crédit immobilier pour vous, simplement et en ligne',
    price: '0',
    priceCurrency: 'EUR',
    seller: organizationSchema,
  },
  operatingSystem: ['Windows', 'iOS', 'Android OS', 'macOS', 'Linux'],
  sameAs,
  url,
})
